// 引入axios封装
import axios from 'axios'
import Qs from 'qs'
import { ElMessage, ElMessageBox } from 'element-plus'
import { COMMON_URL } from '../public/IP'

const BASE_URL = COMMON_URL + '/bls/inventory/storageOut/'

function addStyleNo(param: any, condition: any, result: any) {
    const addUrl = BASE_URL + "addStyleNo";
    console.log(addUrl);
    axios({
        method: "post",
        url: addUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        const param = Qs.stringify({
            outNo: condition.outNo
        });
        query(param, result)
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

function save(data: any) {
    const addUrl = BASE_URL + "save";
    console.log(addUrl);
    axios({
        method: "post",
        url: addUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

function outFromStorage(data: any) {
    const addUrl = BASE_URL + "outFromStorage";
    console.log(addUrl);
    axios({
        method: "post",
        url: addUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

function query(param: any, result: any) {
    const queryUrl = BASE_URL + "query";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        result.value = respone.data.result;
    }).catch(reason => {
        console.log(reason);
    })
}

function queryAllStyleNo(unSelectedStyleNo: any, selectedStyleNo: any) {
    const queryUrl = BASE_URL + "queryTransferData";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        data: ''
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        unSelectedStyleNo.value = respone.data.result.unSelectedStyleNo
        selectedStyleNo.value = respone.data.result.selectedStyleNo
    }).catch(reason => {
        console.log(reason);
    })
}

function billExists(param: any, unSelectedStyleNo: any, selectedStyleNo: any) {
    const queryUrl = BASE_URL + "billExists";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        if (respone.data=='EXISTS') {
            ElMessageBox.alert('入库单号已经存在，请另输入一个新单号', '提示信息')
            return
        }

        queryAllStyleNo(unSelectedStyleNo, selectedStyleNo)
    }).catch(reason => {
        console.log(reason);
    })
}

function generateBillNo(param: any,condition:any, unSelectedStyleNo: any, selectedStyleNo: any) {
    const url = BASE_URL + "generateBillNo";
    console.log(url)
    axios({
        method: "post",
        url: url,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        condition.outNo = respone.data;
        queryAllStyleNo(unSelectedStyleNo, selectedStyleNo)
    }).catch(reason => {
        console.log(reason);
    })
}

export { save, generateBillNo,outFromStorage,query, queryAllStyleNo, addStyleNo, billExists }