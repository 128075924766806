// 引入axios封装
import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import { COMMON_URL } from '../public/IP'

const BASE_URL = COMMON_URL + '/bls/settling/styleCutting/'

function save(data: any) {
    const addUrl = BASE_URL + "save";
    console.log(addUrl);
    axios({
        method: "post",
        url: addUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

function query(param: any, result: any, addData: any,totalQty:any) {
    const queryUrl = BASE_URL + "query";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        result.value = respone.data.result;
        totalQty.total = respone.data.result.length
        if (respone.data.result.length > 0) {
            addData.id = respone.data.result[0].id
            addData.styleNo = respone.data.result[0].styleNo
            addData.cuttingNo = respone.data.result[0].cuttingNo
            addData.cuttingDate = respone.data.result[0].cuttingDate
            addData.bindingQty = respone.data.result[0].bindingQty
            addData.qty = respone.data.result[0].qty
            addData.remark = respone.data.result[0].remark
        } else {
            addData.id = ''
            addData.styleNo = ''
            addData.cuttingNo = ''
            addData.cuttingDate = ''
            addData.bindingQty = ''
            addData.qty = ''
            addData.remark = ''
        }
    }).catch(reason => {
        console.log(reason);
    })
}

function importCuttingQty(file: any) {
    const importCuttingUrl = BASE_URL + "importCuttingQty";
    axios({
        method: "post",
        url: importCuttingUrl,
        headers: {
            'Content-type': 'multipart/form-data'
        },
        data: file,
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        if (reason.data == null) {
            ElMessageBox.alert("文件导入失败", '提示信息')
        } else {
            ElMessageBox.alert(reason.data, '提示信息')
        }
    })
}

function deleteById(param: any) {
    const queryUrl = BASE_URL + "delete";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        console.log(reason);
    })
}

export { save, query,importCuttingQty,deleteById }