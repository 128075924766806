// 引入axios封装
import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import { reactive, ref } from 'vue'
import router from '@/router';
import { isThisQuarter } from 'date-fns';
import { COMMON_URL } from '../../service/public/IP'

const BASE_URL = COMMON_URL+'/system/privileges/'

function queryPrivileges(roleName: any,menuName:any, data: any) {
    const queryUrl = BASE_URL + 'queryPrivileges';
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        params: {
            roleName: roleName,
            menuName: menuName
        }
    }).then(respone => {
        if(respone.data.errorCode=="111111"){
            ElMessageBox.alert(respone.data.errorMsg, '提示信息') 
            return;
        }
        data.value = respone.data.result
    }).catch(reason => {
        console.log(reason)
    })
}

function queryPrivilegeData(roleName: any,viewName:any, unSelectedFunctions: any,selectedFunctions:any) {
    const queryUrl = BASE_URL + 'queryPrivilegeData';
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        params: {
            roleName: roleName,
            viewName: viewName
        }
    }).then(respone => {
        if(respone.data.errorCode=="111111"){
            ElMessageBox.alert(respone.data.errorMsg, '提示信息') 
            return;
        }
        unSelectedFunctions.value = respone.data.result.unSelectedFunctions
        selectedFunctions.value = respone.data.result.selectedFunctions
    }).catch(reason => {
        console.log(reason)
    })
}

function queryDisabledView(data: any) {
    const queryUrl = BASE_URL + 'queryEnabledView';
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Authorization': window.sessionStorage.getItem('token')
        }
    }).then(respone => {
        if(respone.data.errorCode=="111111"){
            ElMessageBox.alert(respone.data.errorMsg, '提示信息') 
            return;
        }
        data.value = respone.data.result
    }).catch(reason => {
        console.log(reason)
    })
}

function savePrivileges(data: any) {
    const savePrivilegesUrl = BASE_URL + "savePrivileges";
    console.log(savePrivilegesUrl);
    axios({
        method: "post",
        url: savePrivilegesUrl,
        headers: {
            'Content-type': 'application/json'
        },
        data
    }).then(respone => {
       // ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

export { queryPrivileges, savePrivileges,queryPrivilegeData,queryDisabledView }