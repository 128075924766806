// 引入axios封装
import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import { COMMON_URL } from '../public/IP'

const BASE_URL = COMMON_URL + '/bls/salary/salary/'

function save(data: any) {
    const addUrl = BASE_URL + "save";
    console.log(addUrl);
    axios({
        method: "post",
        url: addUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

function calculateSalary(data: any) {
    const addUrl = BASE_URL + "calculateSalary";
    console.log(addUrl);
    axios({
        method: "post",
        url: addUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

function query(param: any, result: any) {
    const queryUrl = BASE_URL + "query";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        result.value = respone.data.result;
    }).catch(reason => {
        console.log(reason);
    })
}

function queryYearMonthList(param:any,result: any) {
    const queryUrl = BASE_URL + "queryYearMonthList";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data:param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        result.value = respone.data
    }).catch(reason => {
        console.log(reason);
    })
}

function queryAllFactories(result: any) {
    const queryUrl = BASE_URL + "queryAllFactories";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        }
    }).then(respone => {
        result.value = respone.data
    }).catch(reason => {
        console.log(reason);
    })
}

function queryAllDepartments(result: any) {
    const queryUrl = BASE_URL + "queryAllDepartments";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        }
    }).then(respone => {
        result.value = respone.data
    }).catch(reason => {
        console.log(reason);
    })
}


function exportData(param:any,fileName: any) {
    const queryUrl = BASE_URL + "exportData";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data:param,
        responseType: 'blob'
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        
        const url = window.URL.createObjectURL(new Blob([respone.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    }).catch(reason => {
        console.log(reason);
    })
}

function openSalary(data: any) {
    const url = BASE_URL + "openSalary";
    console.log(url);
    axios({
        method: "post",
        url: url,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

function closeSalary(data: any) {
    const url = BASE_URL + "closeSalary";
    console.log(url);
    axios({
        method: "post",
        url: url,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

export { save,query,queryYearMonthList,calculateSalary, queryAllFactories, queryAllDepartments,exportData,closeSalary,openSalary }