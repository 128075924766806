// 引入axios封装
import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import router from '@/router';
import { COMMON_URL } from '../public/IP'

const BASE_URL = COMMON_URL + '/system/cookie/'

function updateCookie(systemCookie: any) {
    const cookieUrl = BASE_URL + 'updateSystemCookie';
    console.log(cookieUrl)
    axios({
        method: "post",
        url: cookieUrl,
        data: systemCookie
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        console.log(reason)
    })
}

function cookieIsLatest(result:any) {
    const url = BASE_URL + "cookieIsLatest";
    console.log(url);
    axios({
        method: "post",
        url: url,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        }
    }).then(respone => {
        result.value = respone.data
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

export { updateCookie,cookieIsLatest }