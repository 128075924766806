// 引入axios封装
import axios from 'axios'
import Qs from 'qs'
import { ElMessage, ElMessageBox } from 'element-plus'
import router from '@/router';
import { COMMON_URL } from '../public/IP'

const BASE_URL = COMMON_URL + '/bls/purchasing/clothPoSum/'

function sumPo(param: any, poSumData: any) {
    const queryUrl = BASE_URL + "sumPo";   
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        poSumData.value = respone.data.result
    }).catch(reason => {
        console.log(reason);
    })
}

function exportSumPo(data: any, fileName: any) {
    const exportUrl = BASE_URL + "exportSumPo";
    console.log(exportUrl);
    axios({
        method: "post",
        url: exportUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        responseType: 'blob',
        data
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }

        const url = window.URL.createObjectURL(new Blob([respone.data]));
        const link = document.createElement('a');
        link.href = url;

        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    }).catch(reason => {
        if (reason == 'AxiosError: Request failed with status code 500') {
            ElMessageBox.alert("存在没有计算用量的采购单，请先删除或计算采购用量后再导出。", '提示信息')
        } else {
            ElMessageBox.alert(reason, '提示信息')
        }
    })
}

function today() {
    const date: any = new Date()
    let month = date.getMonth() + 1
    let day = date.getDate()

    if (month < 10) {
        month = "0" + month;
    }
    if (day < 10) {
        day = "0" + day;
    }
    const currentMonth = date.getFullYear() + month + day
    return currentMonth;
}

export { sumPo, exportSumPo }