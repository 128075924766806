// 引入axios封装
import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import { COMMON_URL } from '../../public/IP'

const BASE_URL = COMMON_URL + '/bls/prod/cuttingOemBill/'

function createBill(param:any,columnData:any,tableData:any) {
    const createUrl = BASE_URL + "createBill";
    console.log("URL:"+createUrl)
    axios({
        method: "post",
        url: createUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        columnData.value = respone.data.sizeList
        tableData.value = respone.data.dataList
    }).catch(reason => {
        if (reason.data == null) {
            ElMessageBox.alert("创建失败", '提示信息')
        } else {
            ElMessageBox.alert(reason.data, '提示信息')
        }
    })
}

function query(param: any, columnData:any,tableData: any,condition:any,sumData:any) {
    const queryUrl = BASE_URL + "query";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        columnData.value = respone.data.sizeList;
        tableData.value = respone.data.dataList;
        condition.bedNo = respone.data.bedNo;
        sumData.value = '共:'+respone.data.totalRibbon+'扎  '+ respone.data.totalCutQty+'件';
    }).catch(reason => {
        console.log(reason);
    })
}

function querySizeClassNo(param: any, condition:any) {
    const queryUrl = BASE_URL + "querySizeClassNo";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        condition.sizeClassNo = respone.data
    }).catch(reason => {
        console.log(reason);
    })
}

function queryCuttingQty(param: any, condition: any) {
    const queryUrl = BASE_URL + "queryCuttingQty";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
    
        condition.qtyList = respone.data;
    }).catch(reason => {
        console.log(reason);
    })
}

function queryOrder(orderNo:any,tableData:any) {
    const queryUrl = BASE_URL + "queryOrder";
    axios({
        method: "post",
        url: queryUrl,
        data: orderNo
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
     
        tableData.value = respone.data.result
    }).catch(reason => {
        console.log(reason);
    })
}

function queryStyleForCutting(param:any, result: any,) {
    const queryUrl = BASE_URL + "queryStyle";
    axios({
        method: "post",
        url: queryUrl,
        data: param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        result.value = respone.data
    }).catch(reason => {
        console.log(reason);
    })
}

function queryCutOrders(param:any, result: any,) {
    const queryUrl = BASE_URL + "queryCutOrders";
    axios({
        method: "post",
        url: queryUrl,
        data: param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        result.value = respone.data
    }).catch(reason => {
        console.log(reason);
    })
}

function queryAllSizeClass(result: any) {
    const queryUrl = BASE_URL + "queryAllSizeClass";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        }
    }).then(respone => {
        result.value = respone.data
    }).catch(reason => {
        console.log(reason);
    })
}

function queryCuttingBill(param:any,result: any) {
    const queryUrl = BASE_URL + "queryCuttingBill";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        }
    }).then(respone => {
        result.value = respone.data
    }).catch(reason => {
        console.log(reason);
    })
}

function save(param:any) {
    const queryUrl = BASE_URL + "save";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data:param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        console.log(reason);
    })
}

function querySizeClass(result: any) {
    const queryUrl = BASE_URL + "queryAllFactories";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        }
    }).then(respone => {
        result.value = respone.data
    }).catch(reason => {
        console.log(reason);
    })
}

function print(param: any,fileName:any) {
    const url = BASE_URL + "exportBill";
    console.log(url)
    axios({
        method: "post",
        url: url,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data:param,
        responseType: 'blob'
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        
        const url = window.URL.createObjectURL(new Blob([respone.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    }).catch(reason => {
        console.log(reason);
    })
}

function deleteBill(param: any) {
    const deleteUrl = BASE_URL + "delete";
    console.log(deleteUrl)
    axios({
        method: "post",
        url: deleteUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data:param
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        console.log(reason);
    })
}

function addColumn(param:any,columnData:any,tableData:any) {
    const url = BASE_URL + "addColumn";
    console.log("URL:"+url)
    axios({
        method: "post",
        url: url,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        columnData.value = respone.data.sizeList
        tableData.value = respone.data.dataList
    }).catch(reason => {
        if (reason.data == null) {
            ElMessageBox.alert("添加失败", '提示信息')
        } else {
            ElMessageBox.alert(reason.data, '提示信息')
        }
    })
}

function delColumn(param:any,columnData:any,tableData:any) {
    const url = BASE_URL + "delColumn";
    console.log("URL:"+url)
    axios({
        method: "post",
        url: url,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        columnData.value = respone.data.sizeList
        tableData.value = respone.data.dataList
    }).catch(reason => {
        if (reason.data == null) {
            ElMessageBox.alert("删除失败", '提示信息')
        } else {
            ElMessageBox.alert(reason.data, '提示信息')
        }
    })
}

export { query,querySizeClassNo,queryCuttingBill,addColumn,delColumn,queryCuttingQty,queryCutOrders,queryOrder,queryStyleForCutting,queryAllSizeClass,createBill, save, querySizeClass, print,deleteBill }