// 引入axios封装
import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import router from '@/router';
import { COMMON_URL } from '../../service/public/IP'

const BASE_URL = COMMON_URL+'/system/login/'

function userLogin(userName: any, password: any) {
    const loginUrl = BASE_URL + 'userLogin';
    console.log(loginUrl)
    axios({
        method: "post",
        url: loginUrl,
        params: {
            userName: userName,
            password: password
        }
    }).then(respone => {
        window.sessionStorage.setItem("status", respone.data.status);
        window.sessionStorage.setItem("token", respone.data.token);
        if ('SUCCESS' == respone.data.status) {
            router.push('/homeView');
        }
    }).catch(reason => {
        console.log(reason)
    })
}

export {userLogin}