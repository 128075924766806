// 引入axios封装
import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import router from '@/router';
import { COMMON_URL } from '../../service/public/IP'

const BASE_URL = COMMON_URL+'/system/userManagement/'

function addSystemRole(data: any) {
    const addSystemRoleUrl = BASE_URL + "saveSystemRole";
    console.log(addSystemRoleUrl);
    axios({
        method: "post",
        url: addSystemRoleUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded'
        },
        data
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

function deleteSystemRole(data: any) {
    const deleteSystemRoleUrl = BASE_URL + "deleteSystemRole";
    console.log(deleteSystemRoleUrl);
    axios({
        method: "post",
        url: deleteSystemRoleUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded'
        },
        data
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

function querySystemRole(param: any, result: any, totalQty: any, addData: any) {
    const queryUrl = BASE_URL + "querySystemRole";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded'
        },
        data: param
    }).then(respone => {
        if(respone.data.errorCode=="111111"){
            ElMessageBox.alert(respone.data.errorMsg, '提示信息') 
            return;
        }
        result.value = respone.data.result;
        totalQty.total = result.value.length
        if (respone.data.result.length > 0) {
            addData.roleId = respone.data.result[0].roleId
            addData.roleName = respone.data.result[0].roleName
            addData.remark = respone.data.result[0].remark
        } else {
            addData.roleId = null
            addData.roleName = null
            addData.remark = null
        }
    }).catch(reason => {
        console.log(reason);
    })
}

function queryAllSystemRoles(result: any, totalQty: any) {
    const queryUrl = BASE_URL + "querySystemRole";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded'
        }
    }).then(respone => {
        if(respone.data.errorCode=="111111"){
            ElMessageBox.alert(respone.data.errorMsg, '提示信息') 
            return;
        }
        result.value = respone.data.result;
        totalQty.total = result.value.length
    }).catch(reason => {
        console.log(reason);
    })
}

function queryUserInfo(result: any) {
    const queryUrl = BASE_URL + "queryUserInfo";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded'
        }
    }).then(respone => {
        if(respone.data.errorCode=="111111"){
            ElMessageBox.alert(respone.data.errorMsg, '提示信息') 
            return;
        }
        result.value = respone.data.result;
    }).catch(reason => {
        console.log(reason);
    })
}

//System user functions
function saveSystemUser(data: any) {
    const addSystemUserUrl = BASE_URL + "saveSystemUser";
    console.log(addSystemUserUrl);
    axios({
        method: "post",
        url: addSystemUserUrl,
        headers: {
            'Content-type': 'application/json'
        },
        data
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

function deleteSystemUser(data: any) {
    const deleteSystemUserUrl = BASE_URL + "deleteSystemUser";
    console.log(deleteSystemUserUrl);
    axios({
        method: "post",
        url: deleteSystemUserUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded'
        },
        data
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

function querySystemUser(param: any, result: any, totalQty: any, addData: any) {
    const queryUrl = BASE_URL + "querySystemUser";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded'
        },
        data: param
    }).then(respone => {
        if(respone.data.errorCode=="111111"){
            ElMessageBox.alert(respone.data.errorMsg, '提示信息') 
            return;
        }
        result.value = respone.data.result;
        totalQty.total = result.value.length
        if (respone.data.result.length > 0) {
            addData.userId = respone.data.result[0].userId
            addData.userName = respone.data.result[0].userName
            addData.userCname = respone.data.result[0].userCname
            addData.email = respone.data.result[0].email
            addData.remark = respone.data.result[0].remark
            addData.orgId = respone.data.result[0].orgId
            addData.userRoleList = respone.data.result[0].userRoleList
        } else {
            addData.userId = null
            addData.userName = null
            addData.userCname = null
            addData.email = null
            addData.remark = null
            addData.orgId = null
            addData.userRoleList = null
        }
        }).catch(reason => {
            console.log(reason);
        })
}

function queryUserRole(addData: any) {
    const queryUrl = BASE_URL + "queryUserRole";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded'
        }
    }).then(respone => {
        if(respone.data.errorCode=="111111"){
            ElMessageBox.alert(respone.data.errorMsg, '提示信息') 
            return;
        }
        if (respone.data.result.length > 0) {
            addData.userRoleList = respone.data.result
        } else {
            addData.userRoleList = null
        }
        }).catch(reason => {
            console.log(reason);
        })
}

function queryOrganization(result: any) {
    const queryUrl = BASE_URL + "queryOrganization";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        }
    }).then(respone => {
        result.value = respone.data
    }).catch(reason => {
        console.log(reason);
    })
}


export {addSystemRole,queryOrganization, deleteSystemRole, querySystemRole, saveSystemUser, deleteSystemUser, querySystemUser,queryUserRole,queryAllSystemRoles,queryUserInfo }