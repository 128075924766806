// 引入axios封装
import axios from 'axios'
import Qs from 'qs'
import { ElMessage, ElMessageBox } from 'element-plus'
import { COMMON_URL } from '../public/IP'

const BASE_URL = COMMON_URL + '/bls/inventory/storageOutQuery/'


function query(param: any, result: any) {
    const queryUrl = BASE_URL + "query";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        result.value = respone.data.result;
    }).catch(reason => {
        console.log(reason);
    })
}

function exportDetailData(fileName: any) {
    const queryUrl = BASE_URL + "exportDetailData";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        responseType: 'blob'
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        
        const url = window.URL.createObjectURL(new Blob([respone.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    }).catch(reason => {
        console.log(reason);
    })
}

function exportSumData(fileName: any) {
    const queryUrl = BASE_URL + "exportSumData";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        responseType: 'blob'
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        
        const url = window.URL.createObjectURL(new Blob([respone.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    }).catch(reason => {
        console.log(reason);
    })
}

export {query, exportDetailData, exportSumData }