import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'
import './assets/css/global.css'
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios'

axios.interceptors.request.use(config => {
    config.headers.Authorization = window.sessionStorage.getItem('token');
    return config;
}, error => {
    return Promise.reject(error);
});

const app = createApp(App).use(store).use(ElementPlus).use(router)
app.mount('#app')



