// 引入axios封装
import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import router from '@/router';
import { COMMON_URL } from '../public/IP'

const BASE_URL = COMMON_URL + '/bls/financial/clothStore/'

function save(data: any) {
    const addStyleNoUrl = BASE_URL + "saveClothStore";
    console.log(addStyleNoUrl);
    axios({
        method: "post",
        url: addStyleNoUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded'
        },
        data
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

function query(param: any, result: any, addData: any,totalQty:any) {
    const queryUrl = BASE_URL + "query";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        result.value = respone.data.result;
        totalQty.total = respone.data.result.length
        if (respone.data.result.length > 0) {
            addData.id = respone.data.result[0].id
            addData.storeName = respone.data.result[0].storeName
            addData.storeAddr = respone.data.result[0].storeAddr
            addData.contactPerson = respone.data.result[0].contactPerson
            addData.contactMobile = respone.data.result[0].contactMobile
            addData.remark = respone.data.result[0].remark
        } else {
            addData.id = ''
            addData.storeName = ''
            addData.storeAddr = ''
            addData.contactPerson = ''
            addData.contactMobile = ''
            addData.remark = ''
        }
    }).catch(reason => {
        console.log(reason);
    })
}

function deleteStore(param: any) {
    const queryUrl = BASE_URL + "delete";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        console.log(reason);
    })
}

export { save, query,deleteStore }