// 引入axios封装
import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import router from '@/router';
import { COMMON_URL } from '../public/IP'

const BASE_URL = COMMON_URL + '/bls/financial/cloth/'

function save(data: any) {
    const saveUrl = BASE_URL + "save";
    console.log(saveUrl);
    axios({
        method: "post",
        url: saveUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

function calculateStdMeter(param:any,addData: any) {
    const queryUrl = BASE_URL + "calculateStdMeter";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data:param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        addData.meterPerKg = respone.data.meterPerKg
        addData.stdMeter = respone.data.stdMeter
    }).catch(reason => {
        console.log(reason);
    })
}

function query(param: any, result: any, addData: any,totalQty:any) {
    const queryUrl = BASE_URL + "query";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        result.value = respone.data.result;
        totalQty.total =respone.data.result.length
        if (respone.data.result.length > 0) {
            addData.id = respone.data.result[0].id
            addData.clothNo = respone.data.result[0].clothNo
            addData.clothName = respone.data.result[0].clothName
            addData.clothStore = respone.data.result[0].clothStore
            addData.stdQty = respone.data.result[0].stdQty
            addData.stdQtyUnit = respone.data.result[0].stdQtyUnit
            addData.meterPerKg = respone.data.result[0].meterPerKg
            addData.stdMeter = respone.data.result[0].stdMeter
            addData.lackQty = respone.data.result[0].lackQty
            addData.clothWidth = respone.data.result[0].clothWidth
            addData.grammage = respone.data.result[0].grammage
            addData.remark = respone.data.result[0].remark
        } else {
            addData.id = ''
            addData.clothNo = ''
            addData.clothName = ''
            addData.clothStore = ''
            addData.stdQty = ''
            addData.stdQtyUnit = ''
            addData.meterPerKg = ''
            addData.stdMeter = ''
            addData.lackQty = ''
            addData.clothWidth =''
            addData.grammage = ''
            addData.remark = ''
        }
    }).catch(reason => {
        console.log(reason);
    })
}

function queryClothStore(result: any) {
    const queryUrl = BASE_URL + "queryClothStore";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        }
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        result.value = respone.data.result;   
        //ElMessageBox.alert(result.value, '提示信息')
    }).catch(reason => {
        console.log(reason);
    })
}

function queryColor(param: any, result: any, addData: any) {
    const queryUrl = BASE_URL + "queryColor";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        result.value = respone.data.result;
        if (respone.data.result.length > 0) {
            addData.id = respone.data.result[0].id
            addData.colorNo = respone.data.result[0].colorNo
            addData.color = respone.data.result[0].color
            addData.price = respone.data.result[0].price
            addData.remark = respone.data.result[0].remark
        } else {
            addData.id = ''
            addData.colorNo = ''
            addData.color = ''
            addData.price = ''          
            addData.remark = ''
        }
    }).catch(reason => {
        console.log(reason);
    })
}

function saveColor(data: any,clothId:any,colorData:any,addData:any) {
    const saveUrl = BASE_URL + "saveColor";
    console.log(saveUrl);
    axios({
        method: "post",
        url: saveUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
        queryColor(clothId, colorData, addData);
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

function uploadImg(data: any) {
    const uploadImgUrl = BASE_URL + "uploadImg";
    console.log(uploadImgUrl);
    axios({
        method: "post",
        url: uploadImgUrl,
        headers: {
            'Content-type': 'multipart/form-data',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

function queryImg(styleNo: any, styleImg: HTMLImageElement) {
    const queryUrl = BASE_URL + "queryImg";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: styleNo
    }).then(respone => {
        styleImg.src = respone.data
        //styleImg.src = 'http://43.138.167.176/static/img/test.jpg'
    }).catch(reason => {
        console.log(reason);
    })
}

function deleteCloth(param: any) {
    const queryUrl = BASE_URL + "deleteCloth";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')  
    }).catch(reason => {
        console.log(reason);
    })
}

function deleteColor(param: any,clothId:any,colorData:any,addData:any) {
    const queryUrl = BASE_URL + "deleteColor";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        queryColor(clothId, colorData, addData);
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        console.log(reason);
    })
}

export { save,saveColor,calculateStdMeter, query,queryColor,queryClothStore,deleteCloth,deleteColor,uploadImg,queryImg }