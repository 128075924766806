// 引入axios封装
import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import { COMMON_URL } from '../../public/IP'

const BASE_URL = COMMON_URL + '/bls/prod/cuttingBedData/'

function importCuttingBedData(file: any) {
    const importUrl = BASE_URL + "importCuttingBedData";
    axios({
        method: "post",
        url: importUrl,
        headers: {
            'Content-type': 'multipart/form-data'
        },
        data: file,
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        if (reason.data == null) {
            ElMessageBox.alert("文件导入失败", '提示信息')
        } else {
            ElMessageBox.alert(reason.data, '提示信息')
        }
    })
}

function query(param: any, result: any) {
    const queryUrl = BASE_URL + "query";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        result.value = respone.data.result;
    }).catch(reason => {
        console.log(reason);
    })
}

function queryYearMonthList(param:any,result: any) {
    const queryUrl = BASE_URL + "queryYearMonthList";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data:param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        result.value = respone.data
    }).catch(reason => {
        console.log(reason);
    })
}

function queryAllFactories(result: any) {
    const queryUrl = BASE_URL + "queryAllFactories";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        }
    }).then(respone => {
        result.value = respone.data
    }).catch(reason => {
        console.log(reason);
    })
}

function queryAllDepartments(result: any) {
    const queryUrl = BASE_URL + "queryAllDepartments";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        }
    }).then(respone => {
        result.value = respone.data
    }).catch(reason => {
        console.log(reason);
    })
}

export { query,queryYearMonthList, importCuttingBedData, queryAllFactories, queryAllDepartments }