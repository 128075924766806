// 引入axios封装
import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import router from '@/router';
import { COMMON_URL } from '../public/IP'

const BASE_URL = COMMON_URL + '/bls/quotation/clothSubStyle/'

function saveStyleNo(data: any) {
    const addStyleNoUrl = BASE_URL + "save";
    console.log(addStyleNoUrl);
    axios({
        method: "post",
        url: addStyleNoUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded'
        },
        data
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

function query(param: any, result: any, totalQty: any, addData: any) {
    const queryUrl = BASE_URL + "query";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        result.value = respone.data.result;
        totalQty.total = result.value.length
        if (respone.data.result.length > 0) {
            addData.id = respone.data.result[0].id
            addData.styleNo = respone.data.result[0].styleNo
            addData.styleName = respone.data.result[0].styleName
            addData.subStyleNo = respone.data.result[0].subStyleNo
            addData.subStyleName = respone.data.result[0].subStyleName
            addData.colorNo = respone.data.result[0].colorNo
            addData.remark = respone.data.result[0].remark
        } else {
            addData.styleNo = ''
            addData.styleName = ''
            addData.subStyleNo = ''
            addData.subStyleName = ''
            addData.colorNo = ''
            addData.remark = ''
        }
    }).catch(reason => {
        console.log(reason);
    })
}

function queryColor(result: any) {
    const queryUrl = BASE_URL + "queryAllColor";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        }
    }).then(respone => {
        result.value = respone.data
    }).catch(reason => {
        console.log(reason);
    })
}

function queryStyleName(param: any, addData: any) {
    const queryUrl = BASE_URL + "queryStyleName";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }

        addData.styleName = respone.data
    }).catch(reason => {
        console.log(reason);
    })
}

function deleteSubStyle(param: any) {
    const queryUrl = BASE_URL + "delete";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        console.log(reason);
    })
}

export { saveStyleNo,queryColor,query,queryStyleName, deleteSubStyle}