// 引入axios封装
import axios from 'axios'
import Qs from 'qs'
import { reactive, ref, toRaw, toRefs } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { COMMON_URL } from '../public/IP'

const BASE_URL = COMMON_URL + '/bls/order/clothPrepared/'

function query(param: any, result: any) {
    const queryUrl = BASE_URL + "query";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        result.value = respone.data.result;
    }).catch(reason => {
        console.log(reason);
    })
}

function save(param: any) {
    const addUrl = BASE_URL + "save";
    console.log(addUrl);
    axios({
        method: "post",
        url: addUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data:param
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

export {query,save}