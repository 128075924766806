// 引入axios封装
import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import { COMMON_URL } from '../public/IP'

const BASE_URL = COMMON_URL + '/bls/ctc/factoryStdCost/'

function save(data: any) {
    const addUrl = BASE_URL + "save";
    console.log(addUrl);
    axios({
        method: "post",
        url: addUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

function query(param: any, result: any, addData: any,totalQty:any) {
    const queryUrl = BASE_URL + "query";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        result.value = respone.data.result;
        totalQty.total = respone.data.result.length
        if (respone.data.result.length > 0) {
            addData.id = respone.data.result[0].id
            addData.factoryNo = respone.data.result[0].factoryNo
            addData.factoryName = respone.data.result[0].factoryName
            addData.area = respone.data.result[0].area
            addData.employeeQty = respone.data.result[0].employeeQty
            addData.foodAmount = respone.data.result[0].foodAmount
            addData.makingClothQty = respone.data.result[0].makingClothQty
            addData.threadFee = respone.data.result[0].threadFee
            addData.factoryRent = respone.data.result[0].factoryRent
            addData.electricFee = respone.data.result[0].electricFee
            addData.otherFee = respone.data.result[0].otherFee
            addData.mgtFee = respone.data.result[0].mgtFee
            addData.deviceDepreciation = respone.data.result[0].deviceDepreciation
            addData.remark = respone.data.result[0].remark
        } else {
            addData.id = '';
            addData.factoryNo = '';
            addData.factoryName ='';
            addData.area = '';
            addData.employeeQty ='';
            addData.foodAmount = '';
            addData.makingClothQty = '';
            addData.factoryRent ='';
            addData.electricFee ='';
            addData.otherFee ='';
            addData.threadFee = '';
            addData.deviceDepreciation ='';
            addData.mgtFee = '';
            addData.remark = '';
        }
    }).catch(reason => {
        console.log(reason);
    })
}

function deleteFactory(data: any) {
    const addUrl = BASE_URL + "delete";
    console.log(addUrl);
    axios({
        method: "post",
        url: addUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

export { save, query,deleteFactory }