// 引入axios封装
import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import router from '@/router';
import { COMMON_URL } from '../../service/public/IP'

const BASE_URL = COMMON_URL+'/system/loadingService/'

function loadingSystemService() {
    const loadingServiceUrl = BASE_URL + 'loading';
    console.log(loadingServiceUrl)
    axios({
        method: "post",
        url: loadingServiceUrl
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        console.log(reason)
    })
}

export {loadingSystemService}