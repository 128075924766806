// 引入axios封装
import axios from 'axios'
import Qs from 'qs'
import { ElMessage, ElMessageBox } from 'element-plus'
import router from '@/router';
import { COMMON_URL } from '../public/IP'

const BASE_URL = COMMON_URL + '/bls/purchasing/clothPo/'

function query(param: any,  poDetailData: any) {
    const queryUrl = BASE_URL + "queryDetail";   
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        poDetailData.value = respone.data.result
    }).catch(reason => {
        console.log(reason);
    })
}

function queryOrders(param: any,  orderData: any) {
    const queryUrl = BASE_URL + "queryOrders";   
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        orderData.value = respone.data.result
    }).catch(reason => {
        console.log(reason);
    })
}

function generateBillNo(param: any,condition:any, orderData: any) {
    const url = BASE_URL + "generateBillNo";
    console.log(url)
    axios({
        method: "post",
        url: url,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        condition.po = respone.data;
    }).catch(reason => {
        console.log(reason);
    })
}

function createPo(param: any,condition:any, poDetailData: any) {
    const url = BASE_URL + "createPo";   
    console.log(url)
    axios({
        method: "post",
        url: url,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        poDetailData.value = respone.data.result;
        condition.po =  respone.data.result[0].po
    }).catch(reason => {
        console.log(reason);
    })
}

function queryOrder(param:any,unSelectedStyleNo: any, selectedStyleNo: any) {
    const queryUrl = BASE_URL + "queryTransferData";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        data: param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        unSelectedStyleNo.value = respone.data.result.unSelectedStyleNo
        selectedStyleNo.value = respone.data.result.selectedStyleNo
    }).catch(reason => {
        console.log(reason);
    })
}


function queryDetail(param: any, result: any) {
    const queryUrl = BASE_URL + "queryDetail";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        result.value = respone.data.result;
    }).catch(reason => {
        console.log(reason);
    })
}

function importSheInPo(param: any) {
    const importUrl = BASE_URL + "importSheInPo";
    axios({
        method: "post",
        url: importUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        if (reason.data == null) {
            ElMessageBox.alert("排产数据导入失败", '提示信息')
        } else {
            ElMessageBox.alert(reason.data, '提示信息')
        }
    })
}

function importSelfPo(param: any) {
    const importUrl = BASE_URL + "importSelfPo";
    axios({
        method: "post",
        url: importUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        if (reason.data == null) {
            ElMessageBox.alert("排产数据导入失败", '提示信息')
        } else {
            ElMessageBox.alert(reason.data, '提示信息')
        }
    })
}

function updatePo(data: any) {
    const updatingUrl = BASE_URL + "updatePo";
    console.log(updatingUrl);
    axios({
        method: "post",
        url: updatingUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

function refreshPrice(data: any) {
    const url = BASE_URL + "refreshPrice";
    console.log(url);
    axios({
        method: "post",
        url: url,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

function calculate(data: any) {
    const calculatingUrl = BASE_URL + "calculate";
    console.log(calculatingUrl);
    axios({
        method: "post",
        url: calculatingUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

function exportPo(param: any, fileName: any) {
    const exportUrl = BASE_URL + "exportPo";
    console.log(exportUrl);
    axios({
        method: "post",
        url: exportUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        responseType: 'blob',
        data:param
    }).then(respone => {
        const url = window.URL.createObjectURL(new Blob([respone.data]));
        const link = document.createElement('a');
        link.href = url;
        const currentDay = today();
        //const fileName = "面料采购单-" + currentDay + ".zip";
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    }).catch(reason => {
        if (reason == 'AxiosError: Request failed with status code 500') {
            ElMessageBox.alert("没有采购用量数据，请先计算采购用量，再导出数据。", '提示信息')
        } else {
            ElMessageBox.alert(reason, '提示信息')
        }
    })
}

function sumPo(param: any, result: any) {
    const queryUrl = BASE_URL + "sumPo";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        result.value = respone.data.result;
    }).catch(reason => {
        console.log(reason);
    })
}

function deletePo(data: any) {
    const url = BASE_URL + "deletePo";
    console.log(url);
    axios({
        method: "post",
        url: url,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

function today() {
    const date: any = new Date()
    let month = date.getMonth() + 1
    let day = date.getDate()

    if (month < 10) {
        month = "0" + month;
    }
    if (day < 10) {
        day = "0" + day;
    }
    const currentMonth = date.getFullYear() + month + day
    return currentMonth;
}

export {createPo,queryOrders, deletePo,generateBillNo,updatePo,refreshPrice,calculate, query, queryDetail, importSheInPo,importSelfPo, exportPo }