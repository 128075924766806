// 引入axios封装
import axios from 'axios'
import Qs from 'qs'
import { reactive, ref, toRaw, toRefs } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { COMMON_URL } from '../../public/IP'

const BASE_URL = COMMON_URL + '/bls/prod/cuttingBedView/'

function queryBedNo(param: any, result: any) {
    const queryUrl = BASE_URL + "queryBedNo";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        result.value = respone.data.result;
    }).catch(reason => {
        console.log(reason);
    })
}

function query(param: any, result: any) {
    const queryUrl = BASE_URL + "query";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        result.value = respone.data.result;
    }).catch(reason => {
        console.log(reason);
    })
}


export {queryBedNo,query}